import { observable, action, toJS, computed } from "mobx";
import Axios from "axios";
import { appConfig } from "./config";
import { getPrice } from "./helpers";

const order_map = ["Initial", "Second", "Third", "Fourth"];
const date_map = ["", "Jan 2022", "May 2022", "June 2022"];
const percentage_map = [30, 40, 20, 10];

const title_map = [
  "Due at Signing",
  "Due at Production Start",
  "Due at Production Completion",
  "At Delivery",
];

const api = Axios.create({
  baseURL: appConfig.api,
});

api.interceptors.request.use((config) => {
  // config.params["nocache"] = new Date().getTime();
  return config;
});

let currency = localStorage.getItem("nimbus:currency");

export class Store {
  @observable selectedCurrency = currency ? currency : 'sek'

  @action changeCurrency = (currency) => {
    localStorage.setItem("nimbus:currency", currency);
    this.selectedCurrency = currency;
  }

  @observable trackmouse = null;

  @observable broker_active_field = "";

  @action unlock_field = (name) => {
    this.broker_active_field = name;
  };

  @action track_mouse_click = () => {
    if (!this.trackmouse) {
      document.addEventListener("click", (e) => {
        if (this.page.stepIndex === this.page.brokerStep) {
          if (
            this.broker_active_field !== "" &&
            e.target.className !== "brokerage_input"
          ) {
            this.broker_active_field = "";
            this.calculateBrokeragePrice();
          }
        }
      });
    }
  };

  @observable location_name = "default";
  @observable location = {};
  @observable locations = {};
  @observable configuration = {};

  @observable list = [];

  @observable selectedModel = undefined;

  @observable selectAnimate = false;

  @observable user = undefined;

  @observable
  page = {
    lang: "en",
    suffix: "",
    loading: true,
    selectedStep: null,
    completed: false,
    completionType: "", // pdf | payment
    stepIndex: 0,
    summaryStep: 0,
    pdfStep: 0,
    paymentStep: 0,
    completeStep: 0,
    brokerStep: 0,
    error: "",
    hideSteps: false,
    order_number: "",
    original_picture: undefined,
    pdf_path: undefined,
    nextEnabled: false,
    power: {
      unit: "hp",
      value: 0,
    },
    speed: {
      unit: "kn",
      value: 0,
    },
  };

  @observable cover_options = [];

  @observable
  dependencyPopup = {
    visible: false,
    dependant: {},
    dependencies: [],
  };

  @observable
  dependantsPopup = {
    visible: false,
    dependant: {},
    dependencies: [],
  };

  @observable
  detailsForm = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    boating: "",
    delivery: "",
    zipcode: "",
    city: "",
    state: "",
  };

  @observable
  paymentForm = {
    name: "",
    cardnumber: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
    zipcode: "",
  };

  @observable brokerageForm = {
    manager: {
      name: "Anko Mast",
      email: "anko@limitlessyachts.com",
    },
    buyer: {
      // form
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      business_phone: "",
      referred_by: "",
      address: "",
      preferred_delivery_date: "",
    },
    boat: {
      // configurator
      configuration_id: "",
      hull_number: "",
      production_number: "",
      date: "July 31, 2021",
      make: "Limitless Yachts 2022",
      boat_length: "45ft",
      model: "",
      power: "", // engine
      fob: "", // ?
      sales_associate: "",
      extras: "",
    },
    price: {
      base_price: 0, // engine
      additional_price: 0, // selection
      rigging: 0, // form
      extra_cost: 0, // form
      transport: 0, // form
      total: 0, // form
      subtotal: 0, // price - discount
      total_due: 0, // discounted price
      tax: 0,
      tax_percentage: 1,
    },
    discount: {
      // form
      title: "",
      amount: 0,
    },
    payments: [],
    payment_percentage: 0,
  };

  @action calculateBrokeragePrice = () => {
    const { price, discount } = this.brokerageForm;

    if (!price.rigging) price.rigging = 0;
    if (!price.extra_cost) price.extra_cost = 0;
    if (!price.transport) price.transport = 0;

    price.total =
      price.base_price +
      price.additional_price +
      price.rigging +
      price.extra_cost +
      price.transport;

    price.subtotal = price.total - discount.amount;
    price.tax = (price.tax_percentage / 100) * price.subtotal;
    price.total_due = price.subtotal + price.tax;

    this.brokerageForm.payment_percentage = 0;

    for (var x = 0; x < this.brokerageForm.payments.length; x++) {
      var payment = this.brokerageForm.payments[x];
      var order = order_map[x];
      var title = title_map[x];
      var percentage = payment.percentage;
      var date = payment.date;

      var amount =
        (this.brokerageForm.price.total_due * payment.percentage) / 100;
      payment.amount = amount;

      this.brokerageForm.payment_percentage += payment.percentage;

      payment.full_title =
        `${order} Payment ${percentage}% - ${title}` +
        (date ? ` (${date})` : "");
    }
  };

  @observable lastPrice = 0;

  @observable data = { steps: [] };

  @observable state = "configurator"; // configurator | summary | payment | pdf

  @action getLocation = (location) => {
    this.location_name = location;

    api
      .get(`/locations`, {
        params: {
          name: location,
        },
      })
      .then((r) => (this.location = r.data[0]))
      .catch((e) => console.log(e));
  };

  @action loadConfig = () => {
    api
      .get(`/configuration`)
      .then((r) => (this.configuration = r.data))
      .catch((e) => console.log(e));

    const user_str = localStorage.getItem("configurator:user");
    if (user_str) {
      this.user = JSON.parse(user_str);
      console.log(toJS(this.user));
    }

    this.track_mouse_click();
  };

  @action getLocations = () => {
    api
      .get("/locations")
      .then((r) => {
        this.locations = r.data;
        this.page.loading = false;
      })
      .catch((e) => console.log(e));
  };

  @action logout = () => {
    localStorage.removeItem("configurator:user");
    window.location.reload();
  };

  @action getModels = () => {
    api
      .get(`/models?_sort=sorting:asc`)
      .then((r) => {
        this.list = r.data;
        this.page.loading = false;
      })
      .catch((e) => console.log(e));
  };

  @action selectModel = (model) => {
    if (this.selectedModel && model.id === this.selectedModel.id) return;
    this.selectAnimate = true;
    this.selectedModel = model;

    if (this.selectedModel.standards_rich) {
      try {
        var parts = this.selectedModel.standards_rich.split(">>");
        var items = [];

        parts.map((x) => {
          var xxx = x.split("--");
          items.push([xxx[0].replaceAll("\n", ""), xxx[1].replace("\n", "")]);

          return xxx;
        });

        this.selectedModel.tabs = items;
      } catch { }
    }

    setTimeout(() => (this.selectAnimate = false), 1000);
  };

  @action
  init = (slug, number) => {
    api
      .get(`/models/byslug/${slug}`)
      .then(async (r) => {
        this.data = r.data;

        var selections = [];

        try {
          if (number) {
            await api.get(`/payments/bynumber/${number}`).then((r) => {
              this.payment = r.data;

              selections = r.data.raw.map((x) => `${x.id}`);
            });
          } else {
            if (window.location.search !== "") {
              var parts = window.location.search.split("=");
              if (parts.length > 0) {
                selections = atob(parts[1].replaceAll("-", "="))
                  .split(",")
                  .map((x) => x);
              }
            }
          }
        } catch (e) { }

        if (r.data.currency) {
          this.selectedCurrency = r.data.currency;
        }

        window.steps = toJS(r.data.steps);
        this.data.steps = this.data.steps.sort((x, y) => x.sorting - y.sorting);

        this.data.steps.forEach((step) => {
          step.option_groups = step.option_groups.sort(
            (x, y) => y.sorting - x.sorting
          );

          step.option_groups.forEach((option_group) => {
            if (option_group.has_additional) {
              option_group.options.push({
                id: 9999,
                selected: false,
                title: "",
                additional: true,
                price: {
                  usd: 1,
                },
              });
            }

            option_group.options = option_group.options.sort(
              (x, y) => y.sorting - x.sorting
            );

            option_group.options.forEach((option) => {
              // check cover_picture if any add to cover arr
              var exists = selections.find(
                (i) => parseInt(i.split(":")[0]) === option.id
              );

              if (exists) {
                this.selectOption(option, option_group);
                var parts = exists.split(":");
                if (parts.length > 1) {
                  var title = parts[1];
                  option.title = title;
                  option.specified_value = title;
                }
              }

              if (option.power && option.speed && option.selected) {
                if (option.power) {
                  let parts = option.power.split(" ");
                  this.page.power.value = parts[0];
                  this.page.power.unit = parts[1];
                }

                if (option.speed) {
                  let parts = option.speed.split(" ");
                  this.page.speed.value = parts[0];
                  this.page.speed.unit = parts[1];
                }
              }
            });
          });
        });

        if (this.data.standards_rich) {
          try {
            let parts = this.data.standards_rich.split(">>");
            var items = [];

            parts.forEach((x) => {
              var xxx = x.split("--");
              items.push([
                xxx[0].replaceAll("\n", ""),
                xxx[1].replace("\n", ""),
              ]);
            });

            this.data.tabs = items;
          } catch { }
        }

        this.page.hideSteps = false;
        this.page.summaryStep = r.data.steps.length;
        this.page.pdfStep = r.data.steps.length + 1;
        this.page.paymentStep = r.data.steps.length + 2;
        this.page.brokerStep = r.data.steps.length + 3;
        this.page.factoryStep = r.data.steps.length + 4;
        this.page.completeStep = r.data.steps.length + 5;
        this.page.original_picture = r.data.picture;
        this.page.loading = false;

        if (slug.includes("inventory")) {
          this.page.stepIndex = r.data.steps.length;
          this.page.hideSteps = true;
        } else {
          // this.page.stepIndex = 0;
          this.onStepSelected(this.data.steps[0], 0);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  @action popupClose = () => {
    this.dependencyPopup.visible = false;
  };

  @action popupCloseDependant = () => {
    this.dependantsPopup.visible = false;
  };

  @action
  onStepSelected = (step, i) => {
    this.page.stepIndex = i;
    this.page.selectedStep = step;

    if (step.check_scroll) {
      this.page.nextEnabled = false;
    } else {
      this.page.nextEnabled = true;
    }

    setTimeout(() => {
      if (step.target) {
        document.getElementById(step.target).scrollIntoView();
      } else {
        var groups = document.querySelectorAll(".options-wrapper");
        groups.forEach((x) => (x.scrollTop = 0));
      }
    }, 100);
  };

  @action brokerageUpdatePaymentDate = (date, i) => {
    this.brokerageForm.payments[i].date = date;
  };

  @action brokerageUpdatePaymentPercentage = (percentage, i) => {
    this.brokerageForm.payments[i].percentage = percentage;
  };

  @action onBrokerageSalesAgreementCreated = async () => {
    this.page.loading = true;

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api
      .post(
        `/payments/brokerage/pdf`,
        {
          brokerage: toJS(this.brokerageForm),
        },
        config
      )
      .then((r) => {
        this.page.loading = false;
        window.open(r.data);
      })
      .catch((e) => {
        this.page.loading = false;
        alert("An error occured.");
        console.log(e);
      });
  };

  @action onBrokerSalesAgreementSendPdf = async (e) => {
    e.preventDefault();
    this.page.loading = true;
    const { configuration_id } = this.brokerageForm.boat;

    if (!this.brokerageForm.buyer.email) {
      alert("You must provide the buyer's E-mail Address to send pdf");
    }

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api
      .post(
        `/payments/sales-agreement/${configuration_id}/send`,
        {
          brokerage: toJS(this.brokerageForm),
        },
        config
      )
      .then((r) => {
        this.page.loading = false;
        alert("Pdf sent to buyer.");
      })
      .catch((e) => {
        this.page.loading = false;
        alert("An error occured.");
      });
  };

  @action onBrokerSalesAgreementSendDocusign = async () => {
    console.log("onBrokerSalesAgreementSendDocusign");
  };

  @action onBrokerPdfSubmit = async (e) => {
    e.preventDefault();
    this.page.loading = true;
    const { configuration_id } = this.brokerageForm.boat;

    if (!this.brokerageForm.buyer.email) {
      alert("You must provide the buyer's E-mail Address to send pdf");
    }

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api
      .post(
        `/payments/brokerage/pdf/${configuration_id}/send`,
        {
          brokerage: toJS(this.brokerageForm),
        },
        config
      )
      .then((r) => {
        this.page.loading = false;
        alert("Pdf has sent.");
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action onBrokerPdfDownload = async (e) => {
    e.preventDefault();
    this.page.loading = true;
    const { configuration_id } = this.brokerageForm.boat;

    if (!this.brokerageForm.buyer.email) {
      alert("You must provide the buyer's E-mail Address to send pdf");
    }

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api
      .post(
        `/payments/brokerage/pdf/${configuration_id}/download`,
        {
          brokerage: toJS(this.brokerageForm),
        },
        config
      )
      .then((r) => {
        this.page.loading = false;
        window.open(r.data.pdf);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  onBrokerageStepSelected = (step, i) => {
    // save configuration
    this.page.loading = true;
    // this.brokerageForm.boat.sales_associate = this.user.user.fullname;

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api.post(`/payments/brokerage`, this.summary, config).then((r) => {
      const result = r.data;
      const engine = result.options.find((x) => x.power !== undefined);
      const additional = result.options.filter((x) => x.power === undefined);

      let additional_price = 0;

      if (additional.length > 0) {
        additional_price = additional
          .map((x) => x.price.usd)
          .reduce((x, y) => (x > 0 ? x : 0) + (y > 0 ? y : 0));
      }

      this.brokerageForm.boat.model = result.flat_name;
      this.brokerageForm.boat.configuration_id = result.order_number;

      if (engine) {
        this.brokerageForm.boat.power = engine.title;
        this.brokerageForm.price.base_price = engine.price.usd;
        this.brokerageForm.price.additional_price =
          additional_price > 0 ? additional_price : 0;
        this.brokerageForm.price.total = result.purchase_price;
        this.brokerageForm.price.subtotal = result.purchase_price;
        this.brokerageForm.price.tax =
          (this.brokerageForm.price.tax_percentage / 100) *
          this.brokerageForm.price.total;

        this.brokerageForm.price.total_due =
          this.brokerageForm.price.total + this.brokerageForm.price.tax;
      }

      this.brokerageForm.payments = [];
      this.brokerageForm.payment_percentage = 0;

      for (var x = 0; x < 4; x++) {
        var order = order_map[x];
        var title = title_map[x];
        var percentage = percentage_map[x];
        var date = date_map[x];
        var amount = (this.brokerageForm.price.total_due * percentage) / 100;

        var payment = {
          full_title:
            `${order} Payment ${percentage}% - ${title}` +
            (date ? ` (${date})` : ""),
          title: `${order} Payment - ${title}`,
          percentage: percentage,
          amount: amount,
          date: date,
        };

        this.brokerageForm.payments.push(payment);
        this.brokerageForm.payment_percentage += payment.percentage;
      }

      this.page.loading = false;
      this.page.order_number = r.data.order_number;
      this.page.stepIndex = i;
      this.page.selectedStep = step;
      this.page.nextEnabled = true;
    });

    setTimeout(() => {
      if (step.target) {
        document.getElementById(step.target).scrollIntoView();
      } else {
        var groups = document.querySelectorAll(".options-wrapper");
        groups.forEach((x) => (x.scrollTop = 0));
      }
    }, 100);
  };

  @action
  nextStep = () => {
    this.page.stepIndex++;

    const currentStep = this.data.steps[this.page.stepIndex];

    if (currentStep && currentStep.check_scroll) {
      this.page.nextEnabled = false;
    } else {
      this.page.nextEnabled = true;
    }

    setTimeout(() => {
      var groups = document.querySelectorAll(".options-wrapper");
      groups.forEach((x) => (x.scrollTop = 0));
    }, 100);
  };

  @action
  prevStep = () => {
    this.page.stepIndex--;

    setTimeout(() => {
      var groups = document.querySelectorAll(".options-wrapper");
      groups.forEach((x) => (x.scrollTop = 0));
    }, 100);
  };

  @computed get checkNext() {
    const currentStep = this.data.steps[this.page.stepIndex];

    if (!currentStep || !currentStep.required) return true;

    const allselected = currentStep.option_groups
      .filter((x) => x.required)
      .every((og) => og.options.some((x) => x.selected));

    return allselected;
  }

  @action
  onPdfSubmit = (e) => {
    e.preventDefault();
    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    this.page.loading = true;
    this.page.error = "";

    api
      .post(`/payments/pdf`, this.summary, config)
      .then((r) => {
        this.page.loading = false;
        this.page.order_number = r.data.order_number;
        this.page.pdf_path = r.data.pdf;

        this.page.completed = true;
        this.page.completionType = "pdf";
        this.onStepSelected({}, this.page.completeStep);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  onPdfDownload = (e) => {
    e.preventDefault();

    if (this.page.order_number) {
      window.open(
        `${appConfig.api}/payments/pdf/${this.page.order_number}/download`
      );
      return;
    }

    this.page.loading = true;
    this.page.error = "";

    api
      .post(`/payments/pdf/download`, this.summary)
      .then((r) => {
        this.page.loading = false;
        this.page.order_number = r.data.order_number;

        window.open(r.data);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  onPaymentSubmit = (e) => {
    e.preventDefault();

    this.page.loading = true;
    this.page.error = "";

    api
      .post(`/payments/make`, this.summary)
      .then((r) => {
        this.page.loading = false;
        this.page.order_number = r.data;

        if (r.data.stripe_error) {
          this.page.error = "Payment failed: " + r.data.stripe_error;
          return;
        }

        this.page.completed = true;
        this.page.completionType = "payment";
        this.onStepSelected({}, this.page.completeStep);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  selectOption = (option, group, required = false) => {
    if (group.required && option.selected) return;
    if (option.package) return;

    this.lastPrice = this.summary.purchase_price;
    const step = this.data.steps.find((x) => x.id === group.step);
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);
    const options_map = option_group_map.flatMap((x) => x.options);

    if (option.power) {
      var powerparts = option.power.split(" ");
      this.page.power.value = powerparts[0];
      this.page.power.unit = powerparts[1];
    }

    if (option.speed) {
      var speedparts = option.speed.split(" ");
      this.page.speed.value = speedparts[0];
      this.page.speed.unit = speedparts[1];
    }

    // check depenendcy
    const dependants = options_map.filter(
      (o) =>
        o.dependency &&
        o.dependency.find((d) => d.option === option.id) &&
        o.selected
    );

    if (dependants.length > 0) {
      // show remove popup
      console.log("remove selected items");

      this.dependantsPopup.visible = true;
      this.dependantsPopup.dependant = option;
      this.dependantsPopup.dependencies = dependants;
      return;
    }

    if (option.dependency && option.dependency.length > 0 && !option.selected) {
      var dependencyFound = false;
      var dependencyList = [];

      for (let i = 0; i < option.dependency.length; i++) {
        const dependent = this.summary.upgradeOptions.find(
          (x) => x.id === option.dependency[i].option.id
        );

        const dependencyOption = options_map.find(
          (x) => x.id === option.dependency[i].option.id
        );

        if (dependencyOption) {
          dependencyList.push(dependencyOption);
        }

        if (dependent && dependent.selected) {
          dependencyFound = true;
        }
      }

      if (!dependencyFound) {
        this.dependencyPopup.visible = true;
        this.dependencyPopup.dependant = option;
        this.dependencyPopup.dependencies = dependencyList;
        return;
      }
    }

    if (
      group.type === "single" ||
      group.type === "singleImage" ||
      group.type === "picker"
    ) {
      if (!option.selected) {
        group.options.forEach((xo) => {
          xo.selected = false;

          if (xo.related && xo.related.option_group) {
            var ogs = step.option_groups.find(
              (x) => x.id === xo.related.option_group.id
            );

            if (ogs) {
              ogs.options.forEach((x) => (x.selected = false));
            }
          }
        });
        option.selected = true;
      } else {
        if (!required) option.selected = false;
      }

      if (option.related && option.related.option_group) {
        var ogs = step.option_groups.find(
          (x) => x.id === option.related.option_group.id
        );

        if (ogs) {
          ogs.options.forEach((x) => (x.selected = false));
        }
      }
    } else {
      option.selected = !option.selected;
    }

    if (option.related_option) {
      option.related_option.options.forEach((ro) => {
        const roog = option_group_map.find((x) => x.id === ro.option_group);

        if (roog) {
          const ritem = roog.options.find((x) => x.id === ro.id);
          ritem.selected = false;

          if (ritem.related && ritem.related.option_group) {
            var ogs = step.option_groups.find(
              (x) => x.id === ritem.related.option_group.id
            );

            if (ogs) {
              ogs.options.forEach((x) => (x.selected = false));
            }
          }
        }
      });
    }

    var upgradeOptions = this.data.steps.flatMap((x) =>
      x.option_groups.flatMap((og) => og.options)
    );

    if (option.package_content) {
      for (let i = 1; i < upgradeOptions.length; i++) {
        let uoption = upgradeOptions[i];

        if (
          option.package_content.package_content_items.some(
            (x) => x.id === uoption.id
          )
        ) {
          uoption.selected = false;
          uoption.package = option.selected;
          uoption.package_name = option.selected
            ? option[this.langify("title")]
            : undefined;
        }
      }
    }

    if (option.cover_picture) {
      if (option.selected) {
      } else {
      }
    }
  };

  @action selectDependency = (dependency) => {
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);

    const dependency_group = option_group_map.find(
      (x) => x.id === dependency.option_group
    );

    const dependant_group = option_group_map.find(
      (x) => x.id === this.dependencyPopup.dependant.option_group
    );

    this.selectOption(dependency, dependency_group);
    this.selectOption(this.dependencyPopup.dependant, dependant_group);

    this.dependencyPopup = {
      visible: false,
      dependant: {},
      dependencies: [],
    };
  };

  @action removeDependants = () => {
    this.dependantsPopup.dependant.selected = false;
    this.dependantsPopup.dependencies.map((x) => (x.selected = false));

    this.dependantsPopup = {
      visible: false,
      dependant: {},
      dependencies: [],
    };
  };

  @action
  checkOptionRemovable = (option) => {
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);

    const option_group = option_group_map.find(
      (x) => x.id === option.option_group
    );

    return !option_group.required;
  };

  @action
  removeOption = (option) => {
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);

    const option_group = option_group_map.find(
      (x) => x.id === option.option_group
    );

    if (!option_group.required) {
      const found = option_group.options.find((x) => x.id === option.id);
      found.selected = false;
    }
  };

  @action
  goToOption = (option) => {
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);

    const option_group = option_group_map.find(
      (x) => x.id === option.option_group
    );

    const step = this.data.steps.find((x) => x.id === option_group.step);
    const index = this.data.steps.indexOf(step);

    this.onStepSelected(step, index);

    var section = document.querySelector(`#group${option_group.id}`);

    if (section) {
      setTimeout(() => {
        section.scrollIntoView();
      }, 500);
    }
  };

  langify = (key) => {
    return `${key}${this.page.suffix}`;
  };

  groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  @computed get broker() {
    const user_str = localStorage.getItem("configurator:user");

    if (user_str) {
      return JSON.parse(user_str);
    }

    return null;
  }

  prev_image_url = "";

  @computed
  get summary() {
    const data = toJS(this.data);
    let picture_url =
      appConfig.locale.api + `/models/${this.data.slug}/cover.jpg`;

    const result = {
      locale: appConfig.locale,
      location: this.location,
      url: window.location.href,
      model: {
        id: data.id,
        name: data.name,
        title: data.name,
        picture: data.cover,
        selected_picture: picture_url,
        standards: data.standards,
        standards_right: data.standards_right,
        due_today_price: data.due_today_price,
        flat_name: data.flat_name,
        prefix: data.prefix,
      },
      pdf_path: this.page.pdf_path,
      purchase_price: 0,
      due_today_price: this.data.due_today_price,
      refundable: false,
      type: null, // pdf | purchase
      brokerage: this.user !== undefined,
      form: {
        upgradeOptions: [],
        paymentForm: toJS(this.paymentForm),
        detailsForm: toJS(this.detailsForm),
        brokerageForm: toJS(this.brokerageForm),
      },
    };

    if (data.steps.length > 0) {
      result.upgradeOptions = data.steps
        .sort((x, y) => x.sorting - y.sorting)
        .flatMap((x) =>
          x.option_groups
            .sort((x, y) => y.sorting - x.sorting)
            .flatMap((og) => {
              return og.options
                .sort((x, y) => y.sorting - x.sorting)
                .filter((o) => o.selected)
                .map((oo) => ({
                  ...oo,
                  step: x.title,
                  option_group_x: og.title,
                  option_group_title: og.group_title,
                  show_option_group_title: og.show_group_title,
                  title: oo.title,
                }));
            })
        );

      var steps = this.groupBy(result.upgradeOptions, "step");

      for (var key in steps) {
        var step = steps[key];
        steps[key] = this.groupBy(step, "option_group_title");

        for (var key2 in steps[key]) {
          var option_groups = steps[key][key2];
          steps[key][key2] = this.groupBy(option_groups, "option_group_x");
        }
      }

      result.upgradeOptionsDisplay = steps;

      if (result.upgradeOptions.length > 0) {
        result.purchase_price = result.upgradeOptions.reduce((a, b) => {
          return (
            a +
            (b.price !== null
              ? getPrice(b.price, this.location, this.selectedCurrency) > 0
                ? getPrice(b.price, this.location, this.selectedCurrency)
                : 0
              : 0)
          );
        }, 0);
      }
    }

    var selections = toJS(
      result.upgradeOptions.map((x) =>
        x.additional ? x.id + ":" + x.title : x.id
      )
    );

    var cover_options = toJS(
      result.upgradeOptions
        .filter((x) => x.cover_picture !== undefined)
        .map((x) => x.id)
    );

    if (cover_options.length > 0) {
      picture_url += "?q=" + cover_options.join(",");
    }

    result.model.selected_picture = picture_url;
    result.selectedCurrency = this.selectedCurrency;

    result.selectedCurrencyRate = this.location.rates ? this.location.rates[this.selectedCurrency] : 0;

    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?q=" +
      btoa(selections.join()).replaceAll("=", "-");
    window.history.pushState({ path: newurl }, "", newurl);

    return result;
  }
}
